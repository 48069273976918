import { addPropertyControls, ControlType, ComponentFont } from "framer"
ControlType.ResponsiveImage
/**
 *
 * @framerSupportedLayoutWidth any-prefer-fixed
 * @framerSupportedLayoutHeight any-prefer-fixed
 */
export default function ImageWithURL(props) {
    const { imageSourceType, imageUrl, imageFile, altText, objectFit, style } =
        props

    return (
        <div style={{ width: "100%", height: "100%", ...style }}>
            {imageSourceType === "url" ? (
                <img
                    src={imageUrl}
                    alt={altText}
                    style={{ width: "100%", height: "100%", objectFit }}
                />
            ) : (
                <img
                    src={imageFile?.src}
                    srcSet={imageFile?.srcSet}
                    alt={altText}
                    style={{ width: "100%", height: "100%", objectFit }}
                />
            )}
        </div>
    )
}

ImageWithURL.defaultProps = {
    imageSourceType: "url",
    imageUrl: "https://placehold.co/400x400",
    altText: "Imagem de exemplo",
    objectFit: "cover",
}

addPropertyControls(ImageWithURL, {
    imageSourceType: {
        type: ControlType.Enum,
        title: "Tipo de Imagem",
        options: ["url", "file"],
        optionTitles: ["URL de Imagem", "Imagem"],
        defaultValue: "url",
        displaySegmentedControl: true,
        segmentedControlDirection: "vertical",
    },
    font: {
        type: "font",
        controls: "extended",
    },
    imageUrl: {
        type: ControlType.String,
        title: "URL da Imagem",
        hidden(props) {
            return props.imageSourceType === "file"
        },
    },
    imageFile: {
        type: ControlType.ResponsiveImage,
        title: "Imagem",
        hidden(props) {
            return props.imageSourceType === "url"
        },
    },
    altText: {
        type: ControlType.String,
        title: "Texto Alternativo",
        defaultValue: "Imagem",
    },
    objectFit: {
        type: ControlType.Enum,
        title: "Object Fit",
        options: ["fill", "contain", "cover", "none", "scale-down"],
        optionTitles: ["Fill", "Contain", "Cover", "None", "Scale-Down"],
        defaultValue: "cover",
    },
})
